@tailwind base;
@tailwind components;
@tailwind utilities;
body { font-family: "Montserrat", sans-serif; color: #ffffff;}
h1, h2, h3, h4, h5, h6 { font-family: "Archivo Black", sans-serif; }
.b-shadow-inner {
    box-shadow: inset 0em 0em 0.5em 0.5em rgba(0, 0, 0, 0.2);
}
.bannervideo {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.magic-height {
    height: calc(100vh - 77px);
}
.magic-height-2 {
    height: calc(100vh - 154px);
}

@keyframes shining {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
}
  
.star {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    animation-name: shining;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
  
.large-star {
    width: 4px;
    height: 4px;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}
.up-and-down {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@keyframes mover3 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-21px);
    }
}
.up-and-down-2 {
    -webkit-animation: mover3 2s infinite alternate;
    animation: mover3 2s infinite alternate;
}

@keyframes mover2 {
    0% {
        transform: translatex(0);
    }
    100% {
        transform: translatex(10px);
    }
}
.left-and-right {
    -webkit-animation: mover2 3s infinite alternate;
    animation: mover2 3s infinite alternate;
}
span.swiper-pagination-bullet {
    background: #fff !important;
    opacity: 1;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    transform: scale(1.5);
}
.ring-grow {
    -webkit-box-shadow:0px 0px 5px 3px rgba(0,225,255,0.67);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,225,255,0.67);
    box-shadow: 0px 0px 5px 3px rgba(0,225,255,0.67);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.left-and-right {
    -webkit-animation: mover2 3s infinite alternate;
    animation: mover2 3s infinite alternate;
}
.ring-grow-rot {
    -webkit-animation: rotate 10s infinite ;
    animation: rotate 10s infinite ;
    aspect-ratio: 1/1;
}
.what-to-do {
    background: rgb(155,128,186);
    background: linear-gradient(60deg, rgba(155,128,186,0.45) 0%, rgba(14,154,195,.45) 100%);
}
.image-border {
    padding: 9%;
}

.border-cut {
    position: relative;
    -webkit-clip-path: polygon(1% 15%, 1% 22%, 0 22%, 0 35%, 1% 35%, 1% 38%, 0 38%, 0 100%, 100% 100%, 100% 83%, 99% 83%, 99% 79%, 100% 79%, 100% 66%, 99% 66%, 99% 55%, 100% 55%, 100% 0, 0 0, 0 15%);
    clip-path: polygon(1% 15%, 1% 22%, 0 22%, 0 35%, 1% 35%, 1% 38%, 0 38%, 0 100%, 100% 100%, 100% 83%, 99% 83%, 99% 79%, 100% 79%, 100% 66%, 99% 66%, 99% 55%, 100% 55%, 100% 0, 0 0, 0 15%);
}
.profile-warper:hover .profile-hover {
    background-color: transparent;
}
.profile-warper:hover .profile-text {
    display: inline-block;
    margin: 0.5rem -4rem;
}
.profile-text {
    position: absolute;
    display: none;
    margin-top: 10px;
}
.triangle {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 45px solid rgb(114 83 236);
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0px);
}
.chat-text-box {
    background: rgb(51, 51, 204);
    background: linear-gradient(60deg, rgba(51, 51, 204, 1) 0%, rgba(153, 102, 255, 1) 100%);
    margin-top: 0;
    display: inline-block;
}
.concession .swiper-wrapper .swiper-slide{
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .hamburger-menu {
    display: inline-block;
    cursor: pointer;
    width: 30px;
    mix-blend-mode: difference;
}
  .hamburger-menu span {
    display: block;
    width: 24px;
    height: 2px;
    margin-bottom: 6px;
    background: white;
    transition: width 0.3s ease-out;
}
.hamburger-menu:hover span:nth-child(1) {
    width: 15px;
}
.hamburger-menu:hover span:nth-child(2) {
    width: 30px;
}
.hamburger-menu span:nth-child(2) {
    width: 19px;
}
.hamburger-menu:hover span:nth-child(3) {
    width: 20px;
}
.hamburger-menu span:nth-child(3) {
    width: 30px;
    margin-bottom: 0;
}
.pbgx {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 1050vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    height: 100%;
}
.roadmap {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
.pfd-frame iframe {
    height: 100vh;
}
.cc-nav {
    position: absolute;
    top: 50%;
    z-index: 50;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.last-footer {
    height: 85vh;
}
@media (max-width: 768px) {

    .pbgx {
        min-height: 1050vh;
    }
    .roadmap {
        background-position: -82% 5%;
        background-size: 70%;
    }
    .profile-warper:hover .profile-text {
        display: inline-block;
        margin: 0.5rem -2rem;
    }
}